/* RecipeDetail.css */

.customList {
    counter-reset: customCounter;
    list-style-type: none;
    margin: 0 0 1em 0;
    padding-left: 0;
  }
  
  .customListItem {
    position: relative;
    margin-bottom: 0.75em;
    /* padding-top: .5em; */
    padding-left: 2.5em; /* Adjust padding to accommodate the circle */
    font-family: var(--font-family);
    font-size: 14px;
    color: var(--text-color);
  }
  
  .customListItem::before {
    content: counter(customCounter);
    counter-increment: customCounter;
    position: absolute;
    left: 0;
    /* top: 50%; */
    transform: translateY(0); /* Center vertically */
    width: 24px;                  /* Circle diameter */
    height: 24px;                 /* Circle diameter */
    border-radius: 50%;           /* Makes it a circle */
    background-color: var(--primary-color); /* Use theme's primary color */
    color: var(--text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 12px;
  }
  
  @keyframes swipeIconAnimation {
    from {
      left: 90%;
    }
    to {
      left: 15%;
    }
  }
  
  .swipe-icon-animate {
    animation: swipeIconAnimation 1.25s cubic-bezier(.95,-0.2,.83,.67) forwards;
  }